















































































































































































































































































































































































































































































@import 'vue-apps-assets/styles/scss/vars.scss';
table{
  tbody tr {
    cursor: pointer;
  }
  .opened {
    background: $bg;
  }
  .subtable-holder {
    .subtable {
      th{
        white-space: nowrap;
      }
      td {
        word-break: break-all;
      }
      a {
        color: #FFF;
      }
    }
  }
  .long-line {
    word-wrap: break-word;
    word-break: break-all;
    white-space: normal;
    overflow-wrap: break-word;
  }
}
